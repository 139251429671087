.device-verify-popup {
  .stepper-error-message {
    top: -38px;
    font-size: 11px;

    @media (max-width: 400px) {
      font-size: 10px;
    }
    @media (max-width: 380px) {
      font-size: 9px;
    }
    @media (max-width: 350px) {
      top: -50px;
    }
  }

  .stepper-input {
    @media (max-width: 350px) {
      margin-top: 45px;
    }
  }

  .stepper-input-item {
    @media (max-width: 400px) {
      font-size: 40px;
    }
    @media (max-width: 380px) {
      font-size: 35px;
    }
    @media (max-width: 350px) {
      font-size: 30px;
    }
    @media (max-width: 315px) {
      font-size: 25px;
    }
  }
}
