.render-form-field-input {
  @media (max-width: 375px) {
    font-size: 14px !important;
  }
}

.additional-google-sign-in,
.subscribe-main {
  .input-header {
    left: 20px;
  }

  .render-form-field-input {
    @media (max-width: 375px) {
      padding-left: 45px;
    }

    ::placeholder {
      color: #1f2021;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #1f2021;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #1f2021;
    }
  }

  .lower-opacity {
    opacity: 0.6;
  }
}
